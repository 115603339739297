import React from "react";
import { Link } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel2";
import { IoEarOutline } from "react-icons/io5";
import { FaRegAddressBook } from "react-icons/fa6";
import { IoMdPersonAdd } from "react-icons/io";
import { Helmet } from "react-helmet";

import "../Pages/Home.css";
import Floatbutton from "../Functions/Floatbutton";

const options = {
  items: 1,
  nav: false,
  rewind: true,
  autoplay: true,
  animateOut: "fadeOut",
  animateIn: "fadeIn",
};

const Home = () => {
  const handleCall = () => {
    window.location.href = "tel:+64212955629";
  };

  return (
    <div>
      <Helmet>
        <title>Ear Wax Removal in Auckland | CND Hearing Solutions</title>
        <meta
          name="description"
          content="Ear Wax Removal in Auckland is easily attained under the guidance of Ear Suction Specialist in Auckland? Call Chris Joseph Today @ 021–2955629 !."
        />
        <meta
          name="keywords"
          content="Ear Wax Removal, Hearing Solutions, Auckland"
        />
        <meta name="author" content="Branding World" />

        <meta
          property="og:title"
          content="Ear Wax Removal in Auckland | CND Hearing Solutions"
        />
        <meta
          property="og:description"
          content="Ear Wax Removal in Auckland is easily attained under the guidance of Ear Suction Specialist in Auckland? Call Chris Joseph Today @ 021–2955629 !."
        />
        <meta property="og:url" content="https://cndhearingsolution.co.nz/" />
        <meta
          property="og:image"
          content="https://cndhearingsolution.co.nz/image.jpg"
        />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Ear Wax Removal in Auckland | CND Hearing Solutions"
        />
        <meta
          name="twitter:description"
          content="Get expert ear wax removal in Auckland at CND Hearing Solutions. Call Chris Joseph at 021–2955629 for appointments."
        />
        <meta
          name="twitter:image"
          content="https://cndhearingsolution.co.nz/image.jpg"
        />

        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>

      {/*------------------------------------------------ Carousel start----------------------------------------- */}
      <section className="ftco-section" style={{ width: "99%" }}>
        <div className="">
          <div className="row">
            <div className="col-md-12 ">
              <OwlCarousel options={options}>
                <div className="item">
                  <div className="work-wrap d-md-flex">
                    <div className="img">
                      <img src="/assets/images/cnd-ban.jpg"></img>
                    </div>
                    <div className="text  p-4 px-xl-5 d-flex align-items-center ">
                      <div className="desc w-100">
                        <h2 className="mb-4">
                          Welcome to
                          <br></br>
                          <span style={{ color: "blue" }}>
                            CND HEARING{" "}
                          </span>{" "}
                          SOLUTION LTD
                        </h2>
                        <p className="h5">Call: +64212955629</p>
                        <p className="h5 mb-4">
                          yahoo Mail: cndhearing@yahoo.com
                        </p>
                        <div className="row justify-content-start">
                          <div className="col-xl-8">
                            <h6>
                              CND Hearing Solutions Ltd is Independently (NZ)
                              owned
                            </h6>
                            <p>
                              {" "}
                              The company was started in 2014 by Chris Joseph
                              who is an NZAS Qualified Audiometrist and the
                              Director of the company. CND is now trending as
                              topmost ear wax removal auckland.
                            </p>
                          </div>
                        </div>
                        <p>
                          <button
                            type="button"
                            className="btn header-booknow mr-2 mb-2 py-2 px-4"
                            onClick={handleCall}
                          >
                            Contact us for checkup!
                          </button>

                          <Link to="/ear-suction">
                            <button
                              type="button"
                              className="btn header-service mb-2 py-2 px-4"
                            >
                              Service
                            </button>
                          </Link>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>

      <div className="marquee">
        <div className="marquee-content">
          <p>Leave from Oct 14 to Nov 04</p>
          <p>Leave from Oct 14 to Nov 04</p>
          <p>Leave from Oct 14 to Nov 04</p>
          <p>Leave from Oct 14 to Nov 04</p>
        </div>
      </div>

      {/*------------------------------------------------ Carousel end----------------------------------------- */}

      <div className="home-visit py-3">
        <div className="container ">
          <div className="col-12 text-justify mt-3 ">
            <h1 className="text-center">CND Offers Home Visit's</h1>
            <p>
              We can do home visits anywhere in Auckland but at an extra cost.
              Home visits we can be done only on a Saturday. Please look at our
              Services for further details. Opening hours for Ear Wax Removal
              Auckland will be on
              <span
                style={{ fontWeight: "bold", textDecorationLine: "underline" }}
              >
                week days from 6.00 AM to 8.00 PM & Saturday and Sunday from
                10.00 AM to 3.00 PM{" "}
              </span>
              at 10, Tiverton Road, Avondale, Auckland.
            </p>
          </div>
        </div>
      </div>

      <section id="advertisers" class="advertisers-service-sec pt-5 pb-5">
        <div class="container">
          <div class="row"></div>
          <div class="row mt-5 mt-md-4 row-cols-1 row-cols-sm-1 row-cols-md-3 justify-content-center">
            <Link to="/ear-suction">
              <div class="col">
                <div class="service-card">
                  <div class="icon-wrapper">
                    <IoEarOutline style={{ fontSize: "60px" }} />
                  </div>
                  <h3>Our Services</h3>
                  <p>Ear Suction Speclist In Ackland</p>
                </div>
              </div>
            </Link>
            <Link to="/about-us">
              <div class="col">
                <div class="service-card">
                  <div class="icon-wrapper">
                    <IoMdPersonAdd style={{ fontSize: "50px" }} />
                  </div>
                  <h3>About us</h3>
                  <p>CND Hearing Solutions Ltd is Independently (NZ) owned .</p>
                </div>
              </div>
            </Link>
            <Link to="/contact">
              <div class="col">
                <div class="service-card">
                  <div class="icon-wrapper">
                    <FaRegAddressBook style={{ fontSize: "50px" }} />
                  </div>
                  <h3>Contact</h3>
                  <p>
                    Ear Suction-15 Minutes Ear Suction Specialist in Auckland
                  </p>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </section>

      {/*------------------------------------------------ cards-start----------------------------------------- */}
      <section class="py-3 py-md-5">
        <div class="container overflow-hidden">
          <h1 className="text-center">
            Say goodbye to blocked ears with the safest and most reliable ear
            cleaning procedure.
          </h1>
          <div class="row gy-5 mt-5">
            <div class="col-12">
              <div class="row align-items-center flex-row-reverse gy-3 gy-md-0 gx-xl-5">
                <div class="col-xs-12 col-md-6">
                  <div class="img-wrapper position-relative bsb-hover-push  ">
                    <img
                      class="img-fluid rounded  object-fit-cover"
                      loading="lazy"
                      src="./assets/images/home-2.jpg"
                      alt="IMG"
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-md-6 text-justify">
                  <div>
                    <h2 class="h1 mb-3">Ear Cleaning Benefits</h2>
                    <hr></hr>
                    <h5>Improved Hearing:</h5>
                    <p>
                      Removing excess earwax can enhance hearing by clearing any
                      blockages that may impede sound transmission.
                    </p>
                    <hr></hr>
                    <h5>Prevention of Infections:</h5>
                    <p>
                      Proper ear cleaning can help prevent infections by
                      removing debris and bacteria that might accumulate in the
                      ear canal.
                    </p>
                    <hr></hr>
                    <h5>Relief from Discomfort:</h5>
                    <p>
                      Cleaning your ears can relieve discomfort caused by
                      excessive earwax build-up, which can lead to pressure and
                      fullness in the ears.
                    </p>
                    <hr></hr>
                    <h5>Clearer Communication:</h5>
                    <p>
                      By improving hearing, ear cleaning can enhance your
                      ability to communicate effectively.
                    </p>
                    {/* <div className="d-flex justify-content-center">
                      <Link
                        class="btn header-booknow "
                        to="/Booknow"
                        target="_self"
                      >
                        Book Now
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-3">
              <div class="row align-items-center gy-3 gy-md-0 gx-xl-5">
                <div class="col-xs-12 col-md-6">
                  <div class="img-wrapper position-relative bsb-hover-push">
                    {/* <span class="badge rounded-pill text-bg-warning position-absolute top-0 start-0 m-3">
                        Photography
                      </span> */}
                    <img
                      class="img-fluid rounded w-100 h-100 object-fit-cover"
                      loading="lazy"
                      src="./assets/images/home-3.jpg"
                      alt="Photography"
                    />
                  </div>
                </div>
                <div class="col-xs-12 col-md-6">
                  <div>
                    <h2 class="h1 mb-3">Why choose us?</h2>
                    <hr></hr>
                    <h5>No gimmicks.</h5>
                    <p>
                      Our clinics are proud to provide the golden standard
                      treatment to remove ear wax, debris and moisture.
                    </p>
                    <hr></hr>
                    <h5>Our specialised ear nurses.</h5>
                    <p>
                      Our specialist nurses are thoroughly trained in all
                      aspects of ear health. We are also a referral pathway to
                      access further specialist treatment if necessary.
                    </p>
                    <hr></hr>
                    <h5> Ear care.</h5>
                    <p>
                      Quality healthcare delivered to tens of thousands of happy
                      and satisfied clients.
                    </p>
                    <hr></hr>
                    <h5>ACC registered.</h5>

                    {/* <div className="d-flex justify-content-center">
                      <Link
                        class="btn header-booknow "
                        to="/Booknow"
                        target="_self"
                      >
                        Book Now
                      </Link>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Floatbutton />
    </div>
  );
};

export default Home;
