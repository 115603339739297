import React from "react";
import "../Home.css";
import { FaRegHandPointRight } from "react-icons/fa6";
import { Helmet } from "react-helmet";

import { IoEarOutline } from "react-icons/io5";
import Floatbutton from "../../Functions/Floatbutton";

const Earsuction = () => {
  return (
    <>
      <Helmet>
        <title>
          Ear Suction Specialist In Auckland | Ear Suction Specialist
        </title>
        <meta
          name="description"
          content="Call Chris Joseph Today @ 021–2955629 if you are looking for highly experienced Ear Suction Specialist In Auckland."
        />
        <meta
          property="og:title"
          content="Ear Suction Specialist In Auckland | Ear Suction Specialist"
        />
        <meta
          property="og:description"
          content="Call Chris Joseph Today @ 021–2955629 if you are looking for highly experienced Ear Suction Specialist In Auckland."
        />
        <meta name="keywords" content="Ear Suction Specialist In Auckland" />
        <meta name="robots" content="index, follow" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="author" content="Chris Joseph" />

        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://cndhearingsolution.co.nz/" />
        <meta
          property="og:image"
          content="https://cndhearingsolution.co.nz/image.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Ear Suction Specialist In Auckland"
        />
        <meta
          name="twitter:description"
          content="Call Chris Joseph Today @ 021–2955629 if you are looking for a highly experienced topmost Ear Suction Specialist In Auckland."
        />
        <meta
          name="twitter:image"
          content="https://cndhearingsolution.co.nz/image.jpg"
        />
      </Helmet>

      <div className="site-section mt-5" id="about-section">
        <div className="container">
          <div className="row mb-5">
            <div
              className="col-md-3 d-flex justify-content-center ml-auto mb-5 order-md-1"
              data-aos="fade"
            >
              <img
                src="/assets/images/60dollor.png"
                alt="Image"
                className="img-fluid rounded"
                style={{ height: "200px" }}
              />
            </div>
            <div
              className="col-md-9 order-md-2 d-flex align-items-center"
              data-aos="fade"
            >
              <div className="row">
                <div className="col-12">
                  <div className="text-left pb-1">
                    <h3 className="text-black  ">
                      EAR SUCTION SPECIALIST IN AUCKLAND
                    </h3>
                  </div>
                </div>
                <div className="col-12 mb-4">
                  <p className="lead">
                    Difficulty in hearing is a not so uncommon problem that we
                    all need to know more about. It can affect us at any age
                    from birth till old age and good news is that it is a
                    totally treatable condition.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 mb-md-5 mb-0 ">
            <div className="unit-4">
              <div className="unit-4-icon mr-4 mb-3"></div>
              <div className="text-justify">
                <h1 className="text-center">
                  About Ear Suction Specialist In Auckland
                </h1>
                <p>
                  <IoEarOutline style={{ fontSize: "30px" }} /> In Auckland,
                  people in search of specialised ear suction offerings can
                  locate remedy thru a number of clinics and practices staffed
                  with the aid of educated professionals. These specialists,
                  frequently audiologists or ear care practitioners, provide
                  knowledge in the protected and high-quality elimination of ear
                  wax the usage of microsuction techniques.
                </p>
                <p>
                  <IoEarOutline style={{ fontSize: "30px" }} /> Microsuction is
                  a non-invasive approach of ear wax elimination that makes use
                  of a mild suction machine to extract extra wax from the ear
                  canal. This manner is mainly appropriate for persons with
                  touchy ears, these inclined to ear infections, or these with a
                  records of ear surgery. Unlike standard ear syringing methods,
                  microsuction does no longer contain the use of water,
                  decreasing the chance of soreness or complications.
                </p>
                <p>
                  <IoEarOutline style={{ fontSize: "30px" }} />
                  Auckland ear suction experts prioritize affected person
                  alleviation and safety, using modern tools and adhering to
                  strict hygiene protocols all through procedures. They furnish
                  thorough assessments to decide the extent of wax buildup and
                  tailor remedy plans to every individual’s needs.
                </p>
                <p>
                  <IoEarOutline style={{ fontSize: "30px" }} /> Patients can
                  count on a expert and personalised journey when traveling ear
                  suction professionals in Auckland, with handy appointment
                  scheduling and environment friendly strategies designed to
                  alleviate soreness and restoration most fulfilling hearing.
                  Seeking cure from a certified professional ensures peace of
                  thinking and advantageous decision of ear wax-related issues.
                </p>
                <p>
                  <IoEarOutline style={{ fontSize: "30px" }} /> Ear Suction ear
                  wax removal is undertaken using a medical suction device. An
                  extension is fitted to the end of the suction tube which
                  gently removes the earwax from your canal with utmost care.
                </p>
                <p>
                  <IoEarOutline style={{ fontSize: "30px" }} />
                  Ear Suction is the safest and most comfortable method of ear
                  wax removal throughout Auckland, no liquids are used during
                  the procedure which means that there is no mess during the
                  procedure. The fact that we can clearly see the ear canal and
                  more importantly exactly what we are doing makes it an
                  exceptionally safe procedure. It is a comfortable process. Ear
                  Suction Specialist In Auckland available for people over the
                  age 16 years. Payment of $60 for the ear suction can be made
                  online or cash.
                </p>
              </div>
            </div>
          </div>

          <div className="col-12 mb-md-5 mb-0 ">
            <div className="unit-4">
              <div className="unit-4-icon mr-4 mb-3"></div>
              <div className="text-justify">
                <h2>
                  Opening hours for ear suction will be only on Saturday and
                  Sunday from 10AM to 3PM at 10, Tiverton Road, Avondale,
                  Auckland.
                </h2>
                <h1 className="text-center mt-5">
                  What will happen during my appointment?
                </h1>
                <p>
                  <FaRegHandPointRight /> The aim of wax removal by microsuction
                  is to safely and gently clear the ear canal of ear wax
                  (cerumen) and other debris.
                </p>
                <p>
                  <FaRegHandPointRight /> Please put few drops of olive oil or
                  almond oil 3 days prior to the appointment.
                </p>
                <p>
                  {" "}
                  <FaRegHandPointRight /> Your appointment will take
                  approximately 15 minutes.
                </p>
                <p>
                  {" "}
                  <FaRegHandPointRight /> Your Ear Hygienists will explain the
                  procedure.
                </p>
                <p>
                  {" "}
                  <FaRegHandPointRight /> Please ask questions if you have any.
                </p>
                <p>
                  <FaRegHandPointRight /> You asked to recline or lie flat and
                  to remain very still.
                </p>
                <p>
                  <FaRegHandPointRight /> The Ear Hygienists will look into your
                  ear canal using a surgical loupe.
                </p>
                <p>
                  <FaRegHandPointRight /> The Ear Hygienists will direct vision
                  and light using a small cone placed in your ear.
                </p>
                <p>
                  <FaRegHandPointRight /> A small metal tube attached to a
                  suction unit will be carefully placed in your ear canal.
                </p>
                <p>
                  {" "}
                  <FaRegHandPointRight /> The suction will make a windy noise.
                </p>
                <p>
                  {" "}
                  <FaRegHandPointRight /> Wax and debris will be gently and
                  carefully removed.
                </p>
                <p>
                  <FaRegHandPointRight /> Your Ear Hygienists might use forceps,
                  probes and other equipment if necessary.
                </p>
              </div>
            </div>
          </div>
          <div className="col-12 text-justify">
            <div className="unit-4">
              <div className="unit-4-icon mr-4 mb-3"></div>
              <div>
                <h1 className="text-center">
                  Why Ear Suction Specialist In Auckland?
                </h1>
                <p>
                  <IoEarOutline style={{ fontSize: "30px" }} /> Ear suction is
                  accepted across the medical profession as the safest and most
                  comfortable method of earwax removal. With no liquids being
                  used there is no fuss or mess during the procedure. The fact
                  that the ear canal is being observed throughout the process,
                  makes it an exceptionally safe procedure. It is also a
                  comfortable process and it is usually undertaken in a few
                  minutes.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Floatbutton />
    </>
  );
};

export default Earsuction;
